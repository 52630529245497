module.exports = {
	clean: [
		'I look and stare so deep in your eyes',
		'I touch on you more and more every time',
		"When you leave I'm begging you not to go",
		'Call your name two or three times in a row',
		'Such a funny thing for me to try to explain',
		"How I'm feeling and my pride is the one to blame",
		"Cause I know I don't understand",
		'Just how your love can do what no one else can',
		'Got me looking so crazy right now',
		"Your love's got me looking so crazy right now",
		'Your touch got me looking so crazy right now',
		"Got me hoping you'll page me right now",
		"Your kiss got me hoping you'll save me right now",
		"Looking so crazy, your love's got me looking",
		'Got me looking so crazy, your love',
		'When I talk to my friends so quietly',
		'"Who he think he is?" look at what you did to me',
		"Tennis shoes, don't even need to buy a new dress",
		"If you ain't there, ain't nobody else to impress",
		"It's the way that you know what I thought I knew",
		"It's the beat that my heart skips when I'm with you",
		"But I still don't understand",
		'Just how the love your doing no one else can',
		'Got me looking, so crazy, my baby',
		"I'm not myself lately, I'm foolish, I don't do this",
		"I've been playing myself, baby, I don't care",
		"Cause your love's got the best of me",
		"And baby you're making a fool of me",
		"You got me sprung and I don't care who sees",
		'Cause baby you got me, you got me, so crazy, baby',
		'HEY!',
	],
	explicit: [],
};
