module.exports = {
	clean: [
		'You wake up, flawless',
		'Post up, flawless',
		"Ridin' 'round in it, flawless",
		"Flossin' on that, flawless",
		'This diamond, flawless',
		'My diamond, flawless',
		'This rock, flawless',
		'My Roc, flawless',
		'I woke up like this',
		"We flawless, ladies tell 'em",
		'I woke up like this, I woke up like this',
		'Say "I look so good tonight"',
		'Mama taught me good home training',
		'My Daddy taught me how to love my haters',
		'My sister told me I should speak my mind',
		'Shoulders sideways, smack it',
		"Legs movin' side to side",
		'Smack it, smack it in the air',
		'Wave your hands side to side',
		"Clap, clap, clap like you don't care",
		'I know you care',
		'Clap, clap, clap, clap, clap it',
		'Foot up, my foot up',
		'Hold up now my foot up',
		'My hands up, my hands up',
		"Flexin' while my hands up",
		"Spinnin' while my hands up",
		'Hold that cup like alcohol',
		"Don't you drop that alcohol",
		"Man this here like rollin' dice",
		'Seven eleven, seven eleven',
		"Girl I'm tryna kick it with ya",
		'Ooh wee Bey be freaky deaky',
		'Rock that groovy dye dashiki',
		'Nefertiti, edges kinky',
		'Fresher than you',
		"I've been drinkin'",
		'I get filthy when that liquor get into me',
		"Why can't I keep my fingers off it?",
		'Baby, I want you, now-now',
		'Cigars on ice, cigars on ice',
		"Feelin' like an animal with these cameras all in my grill",
		"Flashin' lights, flashin' lights",
		'You got me faded, faded, faded',
		"Can't keep your eyes off my fatty",
		'Drunk in love, I want you',
		'We woke up in the kitchen',
		'We be all night',
		'Drunk in love',
		'We be all night, love, love',
		'No complaints from my body',
		'So fluorescent under these lights',
		"Boy, I'm drinkin', park it in my lot, 7-11",
		"I'm rubbin' on it, rub-rubbin'",
		'If you scared, call that reverend',
		"Boy, I'm drinkin', get my brain right",
		'Armand de Brignac, gangster wife',
		'Louis sheets, he sweat it out',
		'Like washrags, he wet it up',
		'Then I fill the tub up halfway',
		'Then ride it with my surfboard',
		'Surfboard, surfboard',
		"Grainin' on that wood",
		"I'm swervin' on that",
		"Big body been servin' all this",
		"Swerve, surfin' all in this good-good",
		"I'm never tired, never tired",
		"I been sippin', that's the only thing",
		"That's keepin' me on fire, we on fire",
		"Didn't mean to spill that liquor all on my attire",
		"I've been drinkin', watermelon",
		'I want your body right here',
		'Daddy, I want you',
		'See me up in the club with fifty-eleven girls',
		'Posted in the back, diamond fangs in my grill',
		'Brooklyn brim with my eyes sitting low',
		'Every boy in here with me got that smoke',
		'Every girl in here got to look me up and down',
		'All on Instagram, cake by the pound',
		'Circulate the image every time I come around',
		"G's up, tell me how I'm looking babe",
		'Boy this all for you just walk my way',
		"Just tell me how it's looking babe",
		'I do this all for you baby just take aim',
		"And tell me how it's looking babe",
		"Tell me how it's looking babe, looking babe",
		'Drop the bass, mane',
		'The bass get lower',
		'Radio say "speed it up", I just go slower',
		'High like treble',
		'Pumping on the mids',
		"Ya man ain't ever seen a booty like this",
		'And why you think ya keep my name rolling off the tongue',
		"Cuz when he wanna smash I'll just write another one",
		'I sneezed on the beat and the beat got sicker',
		'Yoncé all on his mouth like liquor',
		'Driver, roll up the partition, please',
		'Took 45 minutes to get all dressed up',
	],
	explicit: [
		'God damn, God damn',
		'God damn, God damn, God damn!',
		'My man made me feel so God damn fine',
		'How the hell did this shit happen?',
	],
};
