module.exports = {
	clean: [
		"I'm feelin' myself, I'm feelin' myself, I'm feelin' my",
		'Changed the game with that digital drop',
		'Know where you was when that digital popped',
		'I stopped the world',
		'Male or female, it make no difference',
		'I stop the world, world stop…',
		'Carry on',
		'He say my body stay wetter than the ocean',
		'And he say that Creole in my body is like a potion',
		'I can be a beast or I can give you emotion',
		"But please don't question my devotion",
		"I been giving birth on these haters 'cause I'm fertile",
		'See these double Cs on this bag, murda',
		'Want my double Ds in his bed, Serta',
		'If you really love me make an album about me, word up',
		'Soon as I walk in',
		'Boys start they talkin',
		'Right as that booty sway',
		'Lift up your people',
		'From Texas, Puerto Rico',
		"Dem' islands to México",
		'Hips tick-tock when I dance',
		'On that Demon Time, she might start a OnlyFans',
		'Big B and that B stand for bands',
		"If you wanna see some real ass, baby, here's your chance",
		'I say, left cheek, right cheek, drop it low, then swang',
		'Texas up in this thang, put you up on this game',
		'IVY PARK on my frame',
		'Gang, gang, gang, gang',
		"If you don't jump to put jeans on, baby, you don't feel my pain",
		"Please don't get me hype, write my name in ice",
		"I'm a boss, I'm a leader, I pull up in my two-seater",
		"Shinin', shinin', shinin', shinin', yeah",
		"We gon' take it to the moon, take it to the stars",
		"I'm supercharged",
		"We 'bout to take this whole thing to Mars (Lift off)",
		"Now we gon' take it to the moon, take it to the stars",
		"So many scars",
		"We 'bout to take this whole thing to Mars",
		"Take it to the stars"
	],
	explicit: [
		"Can't argue with these lazy bitches, I just raise my price",
		'And my mama was a savage, nigga, got this shit from Tina',
	],
};
