module.exports = {
	clean: [
		"They don't love you like I love you",
		"Can't you see there's no other man above you?",
		'What a wicked way to treat the girl that loves you',
		"I smell your secrets, and I'm not too perfect",
		'Know that I kept it sexy, you know I kept it fun',
		"What's worse, lookin' jealous or crazy?",
		'Or like being walked all over lately',
		"I'd rather be crazy",
		"I ain't sorry",
		"He trying to roll me up, I ain't picking up",
		'Headed to the club',
		"I ain't thinking 'bout you",
		"Me and my ladies sip my D'USSÉ cup",
		'Middle fingers up, put them hands high',
		'Wave it in his face, tell him, boy, bye',
		"Now you want to say you're sorry",
		'Now you want to call me crying',
		'Now you gotta see me wilding',
		"Now I'm the one that's lying",
		"And I don't feel bad about it",
		"It's exactly what you get",
		'Stop interrupting my grinding',
		'Looking at my watch, he shoulda been home',
		'Today I regret the night I put that ring on',
		'I pray to the Lord you reveal what his truth is',
		'I left a note in the hallway',
		"By the time you read it, I'll be far away",
		"I'm far away",
		"Let's have a toast to the good life",
		'Suicide before you see this tear fall down my eyes',
		"Me and my baby, we gon' be alright",
		"We gon' live a good life",
		'Big homie better grow up',
		"Me and my whoadies 'bout to stroll up",
		'I see them boppers in the corner',
		'They sneaking out the back door',
		"He only want me when I'm not there",
		'He better call Becky with the good hair',
		"Y'all haters corny with that Illuminati mess",
		'Paparazzi, catch my fly and my cocky fresh',
		"I'm so reckless when I rock my Givenchy dress",
		"I'm so possessive so I rock his Roc necklaces",
		'My daddy Alabama, momma Louisiana',
		'You mix that negro with that Creole, make a Texas bama',
		'I like my baby heir with baby hair and afros',
		'I like my negro nose with Jackson Five nostrils',
		'Earned all this money, but they never take the country out me',
		'I got hot sauce in my bag, swag',
		'I see it, I want it, I stunt, yellow bone it',
		"I dream it, I work hard, I grind 'til I own it",
		'I twirl on them haters, albino alligators',
		"El Camino with the seat low, sippin' Cuervo with no chaser",
		'Sometimes I go off, I go hard',
		"Get what's mine, I'm a star",
		"Okay, ladies, now let's get in formation",
		'Prove to me you got some coordination',
		'Slay trick, or you get eliminated',
		'If he hit it right, I might take him on a flight on my chopper',
		"Drop him off at the mall, let him buy some J's, let him shop up",
		'I might get your song played on the radio station',
		'You just might be a black Bill Gates in the making',
		'I just might be a black Bill Gates in the making',
		"Okay, ladies, now let's get in formation",
		'Always stay gracious, best revenge is your paper',
		"Freedom! Freedom! I can't move",
		'Freedom, cut me loose!',
		'Freedom! Freedom! Where are you?',
		'Cause I need freedom too!',
		'I break chains all by myself',
		"Won't let my freedom rot in hell",
		"Hey! I'ma keep running",
		"Cause a winner don't quit on themselves",
		'Tryna rain, tryna rain on the thunder',
		"Tell the storm I'm new",
		"I'ma walk, I'ma march on the regular",
		'Painting white flags blue',
		"Lord forgive me, I've been running",
		'Running blind in truth',
		"I'ma rain, I'ma rain on this bitter love",
		"Tell the sweet I'm new",
		'I\'m telling these tears, "Go and fall away, fall away"',
		'May the last one burn into flames',
		"I'ma wade, I'ma wave through the waters",
		'Tell the tide, "Don\'t move"',
		"I'ma riot, I'ma riot through your borders",
		'Call me bulletproof',
		"Lord forgive me, I've been runnin'",
		"Runnin' blind in truth",
		"I'ma wade, I'ma wave through your shallow love",
		"Tell the deep I'm new",
	],
	explicit: [
		'You know you that bitch when you cause all this conversation',
		"I don't wanna lose my pride, but I'mma fuck me up a bitch",
		"I don't give a fuck, chucking my deuces up",
		'Suck on my balls, pause, I had enough',
		'When he fuck me good, I take his ass to Red Lobster',
		'He always got them fucking excuses',
		"But I ain't fucking with nobody",
	],
};
