module.exports = {
	clean: [
		'Gimme my check, put some respek on my check',
		'Or pay me in equity',
		'Watch me reverse out of debt',
		"We livin' lavish, lavish",
		'I got expensive fabrics',
		'I got expensive habits',
		'He wanna go with me',
		'He wanna be with me',
		'He wanna give me that vitamin D',
		'Ice ornaments, icy style tournaments',
		"You ain't on to this",
		"Don't think they on to this",
		'Bought him a jet',
		'Shut down Colette',
		'Phillippe Patek',
		'Gimme the ball, gimme the ball, take the top shift',
		"Call my girls and put 'em all on a spaceship",
		"Hang one night with Yoncé, I'll make you famous",
		"Have you ever seen the stage goin' apeshit?",
		'Stack my money fast and go',
		'Fast like my Lambo',
		"Jumpin' off the stage, ho",
		'Crowd better savor',
		"Crowd goin' ape",
		"I can't believe we made it",
		"This is what we're thankful for",
		"I can't believe we made it",
		"Have you ever seen the crowd goin' apeshit?",
		'Haters in danger',
		"Whole lot of gangin'",
		'35 chains',
		"I don't give a damn 'bout the fame",
		'G8 planes',
		'Alexander Wang',
		'She a thot that you claim',
		"Can't be toppin' my reign",
		"Poppin', I'm poppin', my bitches all poppin'",
		'We go to the dealer and cop it all',
		"Sippin' my favorite alcohol",
		'Got me so lit, I need Tylenol',
		"All of my people, I free 'em all",
		'Hop in the whip, wanna see the stars',
		"Sendin' the missiles off, drinkin' my inhibitions off",
		'250 for the Richard Mille',
		'My body make Jigga go kneel',
		'Man, my momma, my Lord, my shield',
		"Look at my jewelry, I'm lethal",
		'These diamonds on me, they see-through',
		"I'm a Martian, they wishin' they equal",
		"I got M's like the back of Evisu",
		'Would have put Lemonade up on Spotify',
	],
	explicit: [
		'He got a bad bitch, bad bitch',
		'He like to roll the weed',
		'Get off my dick',
		'If I gave two fucks, two fucks about streaming numbers',
		'Fuck you! Fuck you',
		"You're cool, fuck you, I'm out",
	],
};
