module.exports = {
	clean: [
		"I feel like fallin' in love",
		'I need some drink in my cup',
		"I wanna go missin'",
		'I need a prescription',
		'I wanna go higher',
		'Can I sit on top of you?',
		"I wanna go where nobody's been",
		'Have you ever had fun like this?',
		'Spaceships fly',
		"Bet you you'll see far",
		"Bet you you'll see stars",
		"Bet you you'll elevate",
		"Bet you you'll meet God",
		"'Cause I feel like fallin' in love",
		"What's in these four walls?",
		'You sexy, my love',
		"Don't miss this roll-call",
		"Uh, you Mr. Nasty, I'll clean it up",
		'I wanna go higher, can I sit on top of you?',
		'Black lights',
		'Hypersonic, sex erotic',
		'On my body, boy, you got it',
		"Hit them 'draulics, while I ride it",
		"Got me actin' hella thotty",
		'So excited, so exotic',
		"I'm a seasoned professional",
		"Squeeze it, don't let it go",
		'Tease it, no self control',
		"I can't wait 'to come out and play",
		'Come and cuff it, cuff it, cuff it, cuff it, baby',
		'While I buss it, buss it, buss it, for you baby, ayy',
		"I don't mind, I don't mind",
		"I'm backin' the truck up, huh",
		"I'm puttin' my cup up, huh",
		"Take flight, blindin' lights",
	],
	explicit: [
		'Fuck it up, fuck it up',
		"We gon' fuck up the night",
		"I'm in the mood to fuck somethin' up",
		"Tonight, I'm fuckin' somethin' up, baby",
		"I'm in the mood to fuck somethin' up",
		"We gon' fuck up the night, black lights",
		'Fuck up the night',
		"We gettin' fucked up tonight",
		"We gon' fuck up the night",
		"I'm in the mood to fuck somethin' up",
		"'Cause we gon' fuck up the night",
		"We gon' fuck up the night",
		'Yeah, unapologetic when we fuck up the night',
		'Fuck up tonight',
		"A bitch'll get fucked up, huh",
		'Fuck it up, fuck it up, fuck it up',
		'Unapologetic when we fuck up the night',
	],
};
