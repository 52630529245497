module.exports = {
	clean: [
		'To the left, to the left',
		'Everything you own in the box to the left',
		"In the closet that's my stuff",
		"And keep talking that mess that's fine",
		'But could you walk and talk at the same time',
		"And, it's my name that's on that jag",
		'So come move your bags, let me call you a cab',
		'Standing in the front yard',
		"Tellin' me, how I'm such a fool",
		"Talkin' 'bout, I'll never ever find a man like you",
		'You got me twisted',
		'You must not know about me',
		'I could have another you in a minute',
		"Matter of fact, he'll be here in a minute, baby",
		'I can have another you by tomorrow',
		"So don't you ever for a second get to thinking",
		'Irreplaceable',
		'So go ahead and get gone',
		"Call up that chick and see if she's home",
		"Oops, I bet you thought, that I didn't know",
		'What did you think I was putting you out for',
		'Because you was untrue',
		"Rollin' her around in the car that I bought you",
		'Baby drop them keys',
		'Hurry up before your taxi leaves',
		"So since I'm not your everything",
		"How about I'll be nothing",
		'Nothing at all to you',
		"Baby I won't shed a tear for you",
		"I won't lose a wink of sleep",
		'Cause the truth of the matter is',
		'Replacing you is so easy',
	],
	explicit: ["Yes, if I bought it, nigga please don't touch"],
};
