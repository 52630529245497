module.exports = {
	clean: [
		'All the single ladies',
		'Now put your hands up',
		'Up in the club, just broke up',
		'I’m doing my own little thing',
		'Decided to dip but now you wanna trip',
		'Cause another brother noticed me',
		'I’m up on him, he up on me',
		'Don’t pay him any attention',
		'Cried my tears, three good years',
		'Ya can’t be mad at me',
		'If you liked it then you should have put a ring on it',
		'Don’t be mad once you see that he want it',
		'I put gloss on my lips, a man on my hips',
		'Hold me tighter than my Deréon jeans',
		'Acting up, drink in my cup',
		"I can't care less what you think",
		'I need no permission, did I mention',
		'’Cause you had your turn',
		"But now you gon' learn",
		'What it really feels to miss me',
		"Don't treat me to the things of the world",
		'I’m not that kind of girl',
		'Your love is what I prefer, what I deserve',
		'Here’s a man that makes me, then takes me',
		'And delivers me to a destiny, to infinity and beyond',
		'Pull me into your arms',
		'Say I’m the one you want',
		'If you don’t, you’ll be alone',
		'And like a ghost, I’ll be gone',
	],
	explicit: [],
};
