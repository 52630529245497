module.exports = {
	clean: [
		'Honey, honey',
		'I can see the stars all the way from here',
		"Can't you see the glow on the window pane?",
		"I can feel the sun whenever you're near",
		'Every time you touch me I just melt away',
		"Now everybody asks me why I'm smiling out from ear to ear",
		"Nothing's perfect, but it's worth it",
		'After fighting through my tears',
		'And finally you put me first',
		"Baby, it's you, you're the one I love",
		"Come on, baby, it's you",
		"You're the one that gives your all",
		"You're the one I can always call",
		'When I need you, make everything stop',
		'Finally, you put my love on top',
		'Ooh, come on, baby!',
		'You put my love on top',
		'I can feel the wind whipping past my face',
		'As we dance the night away',
		'Boy your lips taste like a night of champagne',
		'As I kiss you again, and again, and again, and again',
		"You're the one that always call",
		"Baby, you're the one that I love",
		"Baby, you're all I need",
		"You're the only one I see",
		"You're the one I always call",
		"Baby, you're the one that I need",
		"You're the one that always calls",
	],
	explicit: [],
};
