module.exports = {
	clean: [
		"They'll never take my power, my power, my power",
		'This that rhythm, this that lightning',
		"This that burn, this ain't no perm",
		'This that nappy, this that herb',
		'This that kinfolk, this that skinfolk',
		'This that war, this that bloodline',
		'On the frontline, ready for war',
		"Where you gon' run?",
		"I'ma get loose, get loose, get low, get low",
		'Oh yeah, get loose, get loose, get low, get low',
		'Oh-oh-oh, gotta protect my grace',
		'Keep it locked in the safe',
		"Don't make me get back to my ways",
		"My power, they'll never take",
		"Yeah, yeah, I'm for us, all black",
		'All chrome, black-owned',
		'Black tints, matte black',
	],
	explicit: [
		"Fuck these laid edges, I'ma let it shrivel up",
		"Fuck this fade and waves, I'ma let it dread all up",
	],
};
